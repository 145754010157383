import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//import { createExpressionWithTypeArguments } from 'typescript';
import AdminHeader from '../components/Header/adminHeader';
import Header from '../components/Header/header';

export interface UserInterface {
	isAuth?: boolean;
	id?: number;
	name?: string;
	email?: string;
	phoneNumber?: string;
	countryCode?: string;
	mobileNumber?: string;
	organizationName?: string;
	organizationLogo?: string;
	schoolName?: string;
	schoolLogo?: string;
	roles?: string;
	accessToken?: string;
	content?: string;
	isAdmin?: boolean;
}

export interface AdminInterface {
	isAdmin?: boolean;
	Token?: string;
}

export interface AuthInterface {
	user?: UserInterface;
	admin?: AdminInterface;
	saveUser?: (params: UserInterface) => void;
	logoutUser?: (params: UserInterface) => void;
	saveAdmin?: (params: AdminInterface) => void;
	logoutAdmin?: (params: AdminInterface) => void;
}

export const UserContext = React.createContext<AuthInterface | {}>({});

const AuthContextProvider: any = (props: any) => {
	const [user, setUser] = useState<UserInterface>({});
	const [Admin, setAdmin] = useState<AdminInterface>({});
	const location = useLocation();
	useEffect(() => {
		handleShowHeader();
	}, [location]);

	useEffect(() => {
		var retrievedUser = JSON.parse(localStorage.getItem('user') || '{}');
		var isAdmin = localStorage.getItem('isAdmin');
		var adminToken = localStorage.getItem('adminToken');
		if (retrievedUser?.isAuth) {
			saveUser(retrievedUser);
		}
		if (isAdmin === 'true') {
			saveAdmin({ isAdmin: true, Token: adminToken || '' });
		}
	}, []);

	const saveAdmin = (params: AdminInterface) => {
		logoutUser();
		localStorage.setItem('isAdmin', 'true');
		localStorage.setItem('adminToken', params?.Token || '');
		setAdmin(params);
	};

	const saveUser = (params: UserInterface) => {
		localStorage.setItem('user', JSON.stringify({ ...params, isAuth: true }));
		setUser({ ...user, ...params, isAuth: true });
	};
	const logoutUser = () => {
		localStorage.removeItem('user');
		setUser({ isAuth: false });
	};

	const logoutAdmin = () => {
		localStorage.removeItem('isAdmin');
		localStorage.removeItem('adminToken');
		setAdmin({});
	};
	const handleShowHeader = () => {
		var retrievedUser = JSON.parse(localStorage.getItem('user') || '{}');
		if (retrievedUser?.isAuth) {
			return true;
		} else {
			return false;
		}
	};
	return (
		<>
			<UserContext.Provider
				value={{ user, saveUser, logoutUser, saveAdmin, logoutAdmin }}
			>
				{handleShowHeader() && <Header />}
				{!handleShowHeader() && Admin?.isAdmin && <AdminHeader />}
				{props.children}
			</UserContext.Provider>
		</>
	);
};
export default AuthContextProvider;
