import { useEffect, useState } from 'react';
import { Alert } from '../interfaces/alert';
import { AlertStyle } from './alert.style';

export var showToast: any;

const AlertComponent = (props: Alert) => {
	const [show, setShow] = useState<Alert>({});
	showToast = (alert: Alert) => setShow(alert);
	useEffect(() => {
		const timer = show.color == '#f55b6f' ? 10000 : 3000;
		let timer1 = setTimeout(() => setShow({ ...show, open: false }), timer);
		return () => {
			clearTimeout(timer1);
		};
	}, [show]);

	return show.open ? (
		<AlertStyle style={{ justifyContent: 'center', display: 'flex' }}>
			<div
				className='alertContainer'
				style={{ background: show.color || 'Green' }}
			>
				<div
					style={{
						position: 'absolute',
						right: '10px',
						top: '10px',
					}}
				>
					<button
						onClick={() => setShow({ open: false })}
						style={{
							height: '20px',
							width: '20px',
							padding: '2px',
							background: '#FAFBFF',
							borderRadius: '5px',
							borderColor: '#FFFFFF',
							filter: 'drop-shadow(1px 1px -2px black)',
							color: '#2B3D5F',
							fontFamily: 'Sofiaprofont-bold',
							justifyContent: 'center',
						}}
						className='d-flex'
					>
						<h6 style={{ margin: 0, alignSelf: 'center' }}>x</h6>
					</button>
				</div>
				<div className='header'>
					<h4>{show.header}</h4>
				</div>
				<div className='content'>{show.message}</div>
			</div>

			{props.childern}
		</AlertStyle>
	) : (
		<></>
	);
};

export default AlertComponent;
