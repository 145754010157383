import { useContext } from 'react';
import { HeaderStyles, PorfileDropDownStyles } from './header.styles';
import IconsNotifications from '../../assets/icons/header/icons-notification';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../Context/authContext';
import { handlePagesView } from '../../containers/PageRoute/handlePagesView';
import { IconsProfile } from '../../assets/icons/Auth/icons-profile';

const AdminHeader = (props: any) => {
	const location = useLocation().pathname;
	const history = useHistory();
	const { logoutAdmin, Admin } = useContext<any>(UserContext);

	return (
		<div>
			<HeaderStyles>
				<Navbar
					collapseOnSelect
					expand='lg'
					bg='light'
					variant='light'
					className={'navHeader'}
				>
					<Navbar.Brand href='#/admin/home'>
						<img
							src='/logo.png'
							style={{ maxWidth: '60px', maxHeight: '60px' }}
							className={'logoImage'}
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='responsive-navbar-nav' />
					<Navbar.Collapse id='responsive-navbar-nav'>
						<Nav className='mr-auto'>
							{handlePagesView('adminContent.')?.map((v: any) => {
								if (v.status == 'dropDown' && !!v?.pages) {
									return (
										<NavDropdown
											className={`item ${
												location.includes(v.page) && 'active'
											}`}
											title={v.name}
											id='collasible-nav-dropdown-teacher'
										>
											{v?.pages.map((sub: any) => {
												return (
													<NavDropdown.Item>
														<NavLink
															to={sub.page}
															className='item'
															activeClassName='active'
														>
															{sub.name}
														</NavLink>
													</NavDropdown.Item>
												);
											})}
										</NavDropdown>
									);
								} else {
									return (
										<Nav.Link>
											{' '}
											<NavLink
												to={v.page}
												className='item'
												activeClassName='active'
											>
												{v.name}
											</NavLink>{' '}
										</Nav.Link>
									);
								}
							})}
						</Nav>
						<Nav className='right-nav'>
							<Nav.Link>
								{' '}
								<IconsNotifications></IconsNotifications>
							</Nav.Link>
							<Nav.Link>
								<div className='user-icon-container'>
									<PorfileDropDownStyles>
										<Dropdown drop='down'>
											<Dropdown.Toggle variant='success' id='dropdown-basic'>
												<div id='dropdown-basic-button'>
													<IconsProfile
														profileimage={''}
														hasprofileimage={false}
													></IconsProfile>
												</div>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => {
														logoutAdmin();
														history.push('/');
													}}
												>
													Logout
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</PorfileDropDownStyles>

									<div></div>
								</div>
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</HeaderStyles>

			{props.children}
		</div>
	);
};

export default AdminHeader;
