import { useState } from 'react';
import { useEffect } from 'react';

export const IconsProfile = (props: any) => {
	const [profileimagebroken, setprofileimagebroken] = useState(false);
	useEffect(() => {
		if (!!props.profileimage) {
			fetch(props.profileimage)
				.then((response) => {
					if (response.status == 200) setprofileimagebroken(true);
				})
				.catch((err) => {
					setprofileimagebroken(false);
				});
		}
	}, [props.profileimage]);
	const profileimagesvg = (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='19'
			height='22'
			viewBox='0 0 19 22'
		>
			<g fill='none' fillRule='evenodd'>
				<g fill='#BAC2CB' fillRule='nonzero'>
					<g>
						<g>
							<g>
								<path
									d='M15.857 11.152c-.215-.213-.527-.295-.819-.216-.292.08-.519.308-.596.6-.077.293.007.605.222.818 1.446 1.43 2.26 3.38 2.26 5.415 0 1.033-2.97 2.539-7.616 2.539-4.646 0-7.616-1.506-7.616-2.539-.002-2.02.798-3.96 2.226-5.39.328-.33.328-.863 0-1.193-.33-.328-.863-.328-1.193 0C.973 12.928-.008 15.3 0 17.77 0 20.52 4.798 22 9.308 22s9.307-1.48 9.307-4.23c.007-2.487-.987-4.872-2.758-6.618z'
									transform='translate(-558 -319) translate(120 176) translate(0 90) translate(438 53)'
								/>
								<path
									d='M9.308 11.846c3.27 0 5.923-2.652 5.923-5.923S12.579 0 9.308 0C6.036 0 3.385 2.652 3.385 5.923s2.651 5.923 5.923 5.923zm0-10.154c2.336 0 4.23 1.894 4.23 4.231 0 2.337-1.894 4.23-4.23 4.23-2.337 0-4.231-1.893-4.231-4.23 0-2.337 1.894-4.23 4.23-4.23z'
									transform='translate(-558 -319) translate(120 176) translate(0 90) translate(438 53)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
	return (
		<div>
			{!props.hasprofileimage && profileimagesvg}
			{props.hasprofileimage &&
				(profileimagebroken ? (
					<img
						src={props.profileimage}
						style={{ maxWidth: '100%', maxHeight: '100%' }}
					/>
				) : (
					profileimagesvg
				))}
		</div>
	);
};
